import Vue from "vue";
import { api } from "@/services";
import { getTempId, isEqual, isTempId } from "@/helpers";

export const AddressItemStatus = {
  NONE: "",
  CREATED: "created",
  UPDATED: "updated",
  REMOVED: "removed",
};

export const AddressStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const address = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    initialDefaultId: "",
    removedItems: [],
    status: AddressItemStatus.NONE,
    error: {},
    totalItems: 0,
  },
  getters: {
    createdAddresses: (state) => {
      return state.items.filter((item) => item.status === AddressItemStatus.CREATED);
    },
    updatedAddresses: (state) => {
      return state.items.filter((item) => item.status === AddressItemStatus.UPDATED);
    },
    removedAddresses: (state) => {
      return state.removedItems;
    },
    defaultAddress: (state) => {
      return state.items.filter((item) => item.default);
    },
    filterByGivenIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(parseInt(item.id)));
      return selectedItems;
    },
  },
  actions: {
    setInitialDefaultId({ commit }, { initialDefaultId }) {
      commit("SET_INITIAL_DEFAULT_ID", initialDefaultId);
      commit("CHECK_ITEMS_FOR_DEFAULT");
    },
    list({ commit }, { page, itemsPerPage, sort, search, mutationAction = "SET_ITEMS" }) {
      commit("SET_STATUS_LOADING");

      return api.address
        .list(page, itemsPerPage, sort, search)
        .then((response) => {
          commit(`${mutationAction}`, response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByIds({ commit }, { ids, unsetItems = true }) {
      commit("SET_STATUS_LOADING");

      if (unsetItems) {
        commit("UNSET_ITEMS");
      }

      ids.forEach((id) => {
        api.address
          .detail(id)
          .then((response) => {
            commit("ADD_ITEM", response.data);
          })
          .catch((error) => commit("SET_STATUS_ERROR", error));
      });

      commit("UNSET_STATUS");
    },
    detail({ commit }, { id }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");
      return api.address
        .detail(id)
        .then((response) => {
          commit("SET_ITEM", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    setFromLoaded({ commit }, { data }) {
      commit("ADD_ITEM", data);
    },
    createRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.address
        .create(object)
        .then((item) => {
          commit("ADD_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createLocal({ commit }, { object }) {
      commit("ADD_ITEM", object);
    },
    updateRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.address
        .update(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("UPDATE_ITEM", object);

      commit("UNSET_STATUS");
    },
    removeRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.address
        .remove(object.id)
        .then(() => {
          commit("REMOVE_ITEM", object);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    removeLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("REMOVE_ITEM", object);

      commit("UNSET_STATUS");
    },
    addEmptyItem({ commit }) {
      commit("UNSET_STATUS");

      let emptyItem = {
        id: getTempId("address"),
        country: "",
        province: "",
        city: "",
        address1: "",
        address2: "",
        postal_code: "",
        default: false,
      };

      commit("ADD_ITEM", emptyItem);
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetItem({ commit }) {
      commit("UNSET_ITEM");
    },
    unsetRemovedItems({ commit }) {
      commit("UNSET_REMOVED_ITEMS");
    },
    unsetAllItems({ commit }) {
      commit("UNSET_ALL_ITEMS");
    },
  },
  mutations: {
    SET_INITIAL_DEFAULT_ID(state, initalDefaultId) {
      state.initialDefaultId = initalDefaultId;
    },
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ITEM(state) {
      state.item = {};
    },
    UNSET_REMOVED_ITEMS(state) {
      state.removedItems = [];
    },
    UNSET_ALL_ITEMS(state) {
      state.items = [];
      state.item = {};
      state.removedItems = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = AddressStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = AddressStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = AddressStateStatus.NONE;
    },
    ADD_ITEM(state, item) {
      if (state.initialDefaultId && isEqual(item.id, state.initialDefaultId)) {
        item.default = true;
        state.initialDefaultId = "";
      }
      //* register created only for temp items.
      if (isTempId(item.id)) {
        item.status = AddressItemStatus.CREATED;
      }
      state.items.push(item);
      state.totalItems = state.items.length;
    },
    ADD_ITEMS(state, items) {
      Array.prototype.push.apply(state.items, items);
      state.items = [...new Map(state.items.map((item) => [item["id"], item])).values()];
      state.totalItems = state.items.count;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_ITEMS(state, items) {
      if (state.initialDefaultId) {
        items.forEach((item) => {
          if (isEqual(item.id, state.initialDefaultId)) {
            item.default = true;
            state.initialDefaultId = "";
          }
        });
      }
      state.items = items;
      state.totalItems = state.items.length;
    },
    CHECK_ITEMS_FOR_DEFAULT(state) {
      if (!state.items || !state.initialDefaultId) {
        return;
      }
      state.items.forEach((item) => {
        if (isEqual(item.id, state.initialDefaultId)) {
          item.default = true;
          this.state.initialDefaultId = "";
        }
      });
    },
    UPDATE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newItem = { ...item };

      if (newItem.default) {
        if (state.initialDefaultId && isEqual(newItem.id, state.initialDefaultId)) {
          state.initialDefaultId = "";
        }
        // * Reset all items to default - false;
        state.items.forEach((storeItem) => {
          storeItem.default = false;
        });
      }

      let index = state.items.findIndex((stateItem) => isEqual(stateItem.id, item.id));
      if (index !== -1) {
        //* register updates only for "real" items.
        if (!isTempId(item.id)) {
          newItem.status = AddressItemStatus.UPDATED;
        } else {
          newItem.status = state.items[index].status;
        }
        Vue.set(state.items, index, newItem);
      }
    },
    REMOVE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newStateItems = [];
      for (let index = 0; index < state.items.length; ++index) {
        let stateItem = state.items[index];
        if (isEqual(stateItem.id, item.id)) {
          //* register removed only for "real" items.
          if (!isTempId(stateItem.id)) {
            stateItem.status = AddressItemStatus.REMOVED;
            state.removedItems.push(stateItem);
          }
          if (state.initialDefaultId && isEqual(state.initialDefaultId, stateItem.id)) {
            state.initialDefaultId = "";
          }
        } else {
          newStateItems.push(stateItem);
        }
      }
      state.items = newStateItems;
      state.totalItems = state.items.length;
    },
  },
};

export default {
  address,
  AddressStateStatus,
  AddressItemStatus,
};
