import { API_URL } from "./config";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/index";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";

// https://vuejs.org/v2/api/#performance
Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.config.devtools = true;

// prototype function to scroll to the top of the page.
Vue.prototype.$scrollToTheTop = () => window.scrollTo(0, 0);

axios.defaults.baseURL = API_URL;
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
