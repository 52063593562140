import Vue from "vue";
import { api } from "@/services";
import { getTempId, isEqual, isTempId } from "@/helpers";

export const TransportOrderStepStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const TransportOrderStepItemStatus = {
  NONE: "",
  CREATED: "created",
  UPDATED: "updated",
  REMOVED: "removed",
};

export const transportOrderStep = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    removedItems: [],
    status: TransportOrderStepStateStatus.NONE,
    error: {},
    totalItems: 0,
    choices: [],
  },

  getters: {
    createdTransportOrderSteps: (state) => {
      return state.items.filter((item) => item.storeStatus === TransportOrderStepItemStatus.CREATED);
    },
    updatedTransportOrderSteps: (state) => {
      return state.items.filter((item) => item.storeStatus === TransportOrderStepItemStatus.UPDATED);
    },
    removedTransportOrderSteps: (state) => {
      return state.removedItems;
    },
    getChoices: (state) => () => {
      return state.choices;
    },
    filterByGivenTransportOrderStepIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(item.id));
      return selectedItems;
    },
    filterByGivenTOStepIdsFromRouteView: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(item.step_id));
      return selectedItems;
    },
    filterByGivenInvoiceId: (state) => (id) => {
      let steps = [];
      state.items.forEach((item) => {
        if (item.invoices.length) {
          let filteredInvoices = item.invoices.filter(
            (invoice) => invoice.carrier_invoice.toString() === id.toString()
          );

          if (filteredInvoices.length) {
            steps.push(item);
          }
        }
      });

      if (steps.length) {
        steps = [...new Map(steps.map((item) => [item["step_id"], item])).values()];
      }

      return steps;
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");

      return api.transportOrderStep
        .list(page, itemsPerPage, sort, search)
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },

    listRouteTable({ commit }, { page, itemsPerPage, sort, search, filter, carrierId, mutationAction = "SET_ITEMS" }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");

      return api.transportOrderStep
        .listRouteTable(page, itemsPerPage, sort, search, filter, carrierId)
        .then((response) => {
          commit(`${mutationAction}`, response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByTransportOrderId({ commit }, { transportOrderId }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .listByTransportOrder(transportOrderId)
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByCarrierId({ commit }, { carrierId }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .listByCarrier(carrierId)
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .create(object)
        .then((item) => {
          commit("ADD_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createLocal({ commit }, { object }) {
      commit("ADD_ITEM", object);
    },
    detail({ commit }, { id }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");
      return api.transportOrderStep
        .detail(id)
        .then((response) => {
          commit("SET_ITEM", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    setFromLoaded({ commit }, { data }) {
      commit("ADD_ITEMS", data);
    },
    updateRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .update(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    patchRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .patch(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    swapCarriers({ commit }, { data }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .swapCarriers(data)
        .then((items) => {
          Object.values(items).forEach((item) => {
            commit("UPDATE_ITEM", item);
          });
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("UPDATE_ITEM", object);

      commit("UNSET_STATUS");
    },
    removeRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .remove(object.id)
        .then(() => {
          commit("REMOVE_ITEM", object);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    removeLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("REMOVE_ITEM", object);

      commit("UNSET_STATUS");
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetItem({ commit }) {
      commit("UNSET_ITEM");
    },
    unsetRemovedItems({ commit }) {
      commit("UNSET_REMOVED_ITEMS");
    },
    unsetAllItems({ commit }) {
      commit("UNSET_ALL_ITEMS");
    },
    addEmptyItem({ commit, state }) {
      commit("UNSET_STATUS");

      let emptyItem = {
        id: getTempId("transportOrderStep"),
        transport_order: getTempId("transportOrder"),
        chain_number: state.items.length + 1,
        send_transport_order_contract: false,
        status: 0,
        transport_type: "",
        carrier: "",
        carrier_contact_person: "",
        transport: "",
        transport_number: "",
        price_without_vat: 0,
        price_without_vat_currency: "EUR",
      };

      commit("ADD_ITEM", emptyItem);
    },
    getStatuses({ commit }) {
      commit("UNSET_STATUS");

      return api.transportOrderStep
        .getTransportOrderStepStatuses()
        .then((choices) => commit("SET_CHOICES", choices.data))
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
  },
  mutations: {
    SET_CHOICES(state, choices) {
      let choicesList = [];
      for (let key in choices) {
        choicesList.push({ id: Number(key), name: choices[key] });
      }
      state.choices = choicesList;
    },
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ITEM(state) {
      state.item = {};
    },
    UNSET_REMOVED_ITEMS(state) {
      state.removedItems = [];
    },
    UNSET_ALL_ITEMS(state) {
      state.items = [];
      state.item = {};
      state.removedItems = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = TransportOrderStepStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = TransportOrderStepStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = TransportOrderStepStateStatus.NONE;
    },
    ADD_ITEM(state, item) {
      item.storeStatus = TransportOrderStepItemStatus.CREATED;
      state.items.push(item);
      state.totalItems = state.items.length;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_ITEMS(state, items) {
      state.items = items.results;
      state.totalItems = items.count;
    },
    ADD_ITEMS(state, items) {
      items.results.forEach((item) => {
        if (state.items.findIndex((stateItem) => isEqual(stateItem.step_id, item.step_id)) === -1) {
          state.items.push(item);
        }
      });

      state.totalItems = state.items.count;
    },
    UPDATE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let index = state.items.findIndex((stateItem) => isEqual(stateItem.id, item.id));
      if (index !== -1) {
        if (!isTempId(item.id)) {
          item.storeStatus = TransportOrderStepItemStatus.UPDATED;
        } else {
          item.storeStatus = state.items[index].storeStatus;
        }
        Vue.set(state.items, index, item);
      }
    },
    REMOVE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newStateItems = [];
      for (let index = 0; index < state.items.length; ++index) {
        let stateItem = state.items[index];
        if (isEqual(stateItem.id, item.id)) {
          if (!isTempId(stateItem.id)) {
            stateItem.storeStatus = TransportOrderStepItemStatus.REMOVED;
            state.removedItems.push(stateItem);
          }
        } else {
          newStateItems.push(stateItem);
        }
      }
      state.items = newStateItems;
      state.totalItems = state.items.length;
    },
  },
};

export default {
  transportOrderStep,
  TransportOrderStepStateStatus,
  TransportOrderStepItemStatus,
};
