<template>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on }">
            <v-btn large icon dark v-on="on">
                <v-icon size="30" color="green">mdi-palette</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">Dark Mode</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action><v-switch v-model="$vuetify.theme.dark" /></v-list-item-action>
            </v-list-item>
            <v-divider />
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "ThemeChanger",
    data: () => ({
        menu: false,
    }),
    watch: {
        "$vuetify.theme.dark"() {
            localStorage.setItem("theme.dark", this.$vuetify.theme.dark);
        },
    },
    created: function () {
        let isDarkStored = localStorage.getItem("theme.dark");
        let isDark;

        switch (isDarkStored) {
            case null:
            case "true":
                isDark = true;
                break;

            case "false":
                isDark = false;
                break;
        }

        this.$vuetify.theme.dark = isDark;
    },
};
</script>
<style scoped></style>
