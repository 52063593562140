export const ValidationRules = {
  required: function (value) {
    return !!value || "Required";
  },

  number: function (value) {
    if (value) {
      let pi = parseInt(value);
      return (!isNaN(pi) && pi.toString() === value.toString()) || "Value must be an integer";
    } else {
      return true;
    }
  },

  float: function (value) {
    let pf = parseFloat(value);
    return (!isNaN(pf) && pf.toString() === value.toString()) || "Value must be a number";
  },

  anyNumber: function (value) {
    let pf = parseFloat(value);
    return (!isNaN(pf) && !isNaN(Number(value))) || "Value must be a number";
  },

  email: function (value) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail";
  },

  selectRequired: function (value) {
    let isArray = false;

    if (Array.isArray(value)) {
      value = value.filter((n) => n);
      isArray = true;
    }

    return (!!value && !isArray) || (isArray && value.length > 0) || "Selection is required";
  },
};

export default {
  ValidationRules,
};
