import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPatch,
  axiosPut,
  getSeparator,
  axiosUpload,
  formatFilter,
} from "@/helpers";

export const transportOrder = {
  list,
  create,
  detail,
  update,
  updatePartial,
  remove,
  getTransportOrderStatuses,
  resendContracts,
  listFlatTable,
  upload,
  listDocumentsByEntryId,
  deleteFile,
  clone,
};

const URL = "/api/v1/transport-orders/";

function list(page, itemsPerPage, sort, search) {
  let url = URL;
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }

  return axiosGet(url);
}

function listFlatTable(page, itemsPerPage, sort, search, filter) {
  let url = URL + "flat_table/";
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }

  url = formatFilter(url, filter);

  return axiosGet(url);
}

function create(data) {
  return axiosPost(URL, data);
}

function detail(id, expand) {
  if (expand) {
    return axiosGet(`${URL}${id}/?expand=${expand}`);
  }
  return axiosGet(`${URL}${id}/`);
}

function update(id, data) {
  return axiosPut(`${URL}${id}/`, data);
}

function updatePartial(id, data) {
  return axiosPatch(`${URL}${id}/`, data);
}

function remove(id) {
  return axiosDelete(`${URL}${id}/`);
}

function getTransportOrderStatuses() {
  return axiosGet(`${URL}statuses/`);
}

function resendContracts(id) {
  return axiosPost(`${URL}${id}/resend_contracts/`);
}

function upload(id, data) {
  let formData = new FormData();
  data.forEach((file) => {
    formData.append("files", file);
  });
  return axiosUpload(`${URL}${id}/upload/`, formData);
}

function listDocumentsByEntryId(entryId) {
  return axiosGet(`${URL}${entryId}/documents/`);
}

function deleteFile(transportOrderId, fileId) {
  return axiosDelete(`${URL}${transportOrderId}/delete-file/${fileId}`);
}

function clone(transportOrderId) {
  return axiosPost(`${URL}${transportOrderId}/clone/`, {});
}
