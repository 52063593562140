import { api } from "@/services";
import { isEqual } from "@/helpers";

export const CarrierPricingStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const carrierPricing = {
  namespaced: true,
  state: {
    items: [],
    carrierPricing: {},
    status: CarrierPricingStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  getters: {
    getById: (state) => (id) => {
      return state.items.filter((item) => isEqual(item.id, id));
    },
    filterByAddressesAndCarrier: (state) => (addresses, carrier) => {
      let filteredItems = [];
      state.items.forEach((item) => {
        for (let i = 0; i < addresses.length; i++) {
          if (
            isEqual(item.pickup_address, addresses[i].pickup_address) &&
            isEqual(item.delivery_address, addresses[i].delivery_address) &&
            isEqual(item.carrier, carrier)
          ) {
            filteredItems.push(item);
          }
        }
      });
      return filteredItems;
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, addresses, carrier, expand, filter }) {
      commit("UNSET_ERROR");

      return api.carrierPricing
        .list(page, itemsPerPage, sort, addresses, carrier, expand, filter)
        .then((carrierPricing) => commit("GET_PAGINATED", carrierPricing.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.carrierPricing
        .create(data)
        .then((carrierPricing) => commit("CREATE_CARRIER_PRICING", carrierPricing.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit }, { id }) {
      commit("UNSET_ERROR");
      commit("UNSET_CARRIER_PRICING");

      return api.carrierPricing
        .detail(id)
        .then((carrierPricing) => commit("SET_CARRIER_PRICING", carrierPricing.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.carrierPricing
        .update(id, data)
        .then((carrierPricing) => commit("UPDATE_CARRIER_PRICING", carrierPricing.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.carrierPricing.remove(id).then(() => commit("DELETE_CARRIER_PRICING", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetCarrierPricing({ commit }) {
      commit("UNSET_CARRIER_PRICING");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = CarrierPricingStateStatus.NONE;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = CarrierPricingStateStatus.ERROR;
    },
    GET_PAGINATED(state, carrierPricing) {
      state.items = carrierPricing.results;
      state.totalItems = carrierPricing.count;
    },
    SET_ITEMS(state, carrierPricing) {
      state.items = carrierPricing;
    },
    SET_CARRIER_PRICING(state, carrierPricing) {
      state.carrierPricing = carrierPricing;
    },
    UNSET_CARRIER_PRICING(state) {
      state.carrierPricing = {};
    },
    UPDATE_CARRIER_PRICING(state, carrierPricing) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (carrierPricing.id === state.items[i].id) {
            state.items[i] = carrierPricing;
            break;
          }
        }
      }
    },
    DELETE_CARRIER_PRICING(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_CARRIER_PRICING(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  carrierPricing,
  CarrierPricingStateStatus,
};
