import { axiosDelete, axiosGet, axiosPost, axiosPut, getSeparator } from "@/helpers";

export const transportOrderStepLine = {
  list,
  create,
  detail,
  update,
  remove,
  getTransportOrderStepLineKinds,
  listByTransportOrderStep,
  listByTransportOrder,
};

const URL = "/api/v1/transport-order-step-lines/";

function list(page, itemsPerPage, sort, search) {
  let url = URL;
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }

  return axiosGet(url);
}

function create(data) {
  return axiosPost(URL, data);
}

function detail(id) {
  return axiosGet(`${URL}${id}/`);
}

function update(id, data) {
  return axiosPut(`${URL}${id}/`, data);
}

function remove(id) {
  return axiosDelete(`${URL}${id}/`);
}

function getTransportOrderStepLineKinds() {
  return axiosGet(`${URL}kinds/`);
}

function listByTransportOrderStep(transportOrderStepId) {
  return axiosGet(`${URL}?transport_order_step=${transportOrderStepId}`);
}

function listByTransportOrder(transportOrderId) {
  return axiosGet(`${URL}?transport_order=${transportOrderId}&ordering=line_number&page_size=100&page=1`);
}
