import { api } from "@/services";
import { isEqual } from "@/helpers";

export const CarrierInvoiceStateStatus = {
  NONE: "",
  ERROR: "error",
  LOADING: "loading",
};

export const carrierInvoice = {
  namespaced: true,
  state: {
    items: [],
    carrierInvoice: {},
    status: CarrierInvoiceStateStatus.NONE,
    error: {},
    totalItems: 0,
    documents: [],
  },
  getters: {
    filterByGivenIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(item.id));
      return selectedItems;
    },
    getById: (state) => (id) => {
      return state.items.filter((item) => isEqual(item.id, id));
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search, expand, filter }) {
      commit("UNSET_ERROR");

      return api.carrierInvoice
        .list(page, itemsPerPage, sort, search, expand, filter)
        .then((carrierInvoices) => commit("GET_PAGINATED", carrierInvoices.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.carrierInvoice
        .create(data)
        .then((carrierInvoice) => {
          commit("CREATE_CARRIER_INVOICE", carrierInvoice.data);
          return carrierInvoice.data;
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit }, { id }) {
      commit("UNSET_ERROR");
      commit("UNSET_CARRIER_INVOICE");

      return api.carrierInvoice
        .detail(id)
        .then((carrierInvoice) => commit("SET_CARRIER_INVOICE", carrierInvoice.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.carrierInvoice
        .update(id, data)
        .then((carrierInvoice) => commit("UPDATE_CARRIER_INVOICE", carrierInvoice.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    clone({ commit }, { object }) {
      commit("UNSET_ERROR");
      return api.carrierInvoice
        .clone(object.id)
        .then((item) => {
          return item.data;
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.carrierInvoice.remove(id).then(() => commit("DELETE_CARRIER_INVOICE", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetCarrierInvoice({ commit }) {
      commit("UNSET_CARRIER_INVOICE");
    },
    upload({ commit }, { id, files }) {
      commit("UNSET_STATUS");

      return api.carrierInvoice
        .upload(id, files)
        .then((response) => {
          commit("ADD_DOCUMENTS", response.data);
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    downloadFiles({ commit }, { carrierInvoiceId, fileId }) {
      commit("UNSET_STATUS");

      return api.carrierInvoice.downloadFiles(carrierInvoiceId, fileId).catch((error) => commit("SET_ERROR", error));
    },
    listDocumentsByEntryId({ commit }, { entryId }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_DOCUMENTS");

      return api.carrierInvoice
        .listDocumentsByEntryId(entryId)
        .then((response) => {
          commit("SET_DOCUMENTS", response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    unsetDocuments({ commit }) {
      commit("UNSET_DOCUMENTS");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = CarrierInvoiceStateStatus.NONE;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = CarrierInvoiceStateStatus.NONE;
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    ADD_DOCUMENTS(state, docs) {
      docs.forEach((item) => state.documents.push(item));
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = CarrierInvoiceStateStatus.LOADING;
    },
    UNSET_DOCUMENTS(state) {
      state.documents = [];
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = CarrierInvoiceStateStatus.ERROR;
    },
    GET_PAGINATED(state, carrierInvoices) {
      state.items = carrierInvoices.results;
      state.totalItems = carrierInvoices.count;
    },
    SET_ITEMS(state, carrierInvoices) {
      state.items = carrierInvoices;
    },
    SET_CARRIER_INVOICE(state, carrierInvoice) {
      state.carrierInvoice = carrierInvoice;
    },
    UNSET_CARRIER_INVOICE(state) {
      state.carrierInvoice = {};
    },
    UPDATE_CARRIER_INVOICE(state, carrierInvoice) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (carrierInvoice.id === state.items[i].id) {
            state.items[i] = carrierInvoice;
            break;
          }
        }
      }
    },
    DELETE_CARRIER_INVOICE(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_CARRIER_INVOICE(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  carrierInvoice,
  carrierInvoiceStateStatus: CarrierInvoiceStateStatus,
};
