import { axiosDelete, axiosGet, axiosPost, axiosPut, getSeparator } from "@/helpers";

export const category = {
  list,
  create,
  detail,
  update,
  remove,
};

function list(page, itemsPerPage, sort, search, filter) {
  let url = "/api/v1/categories/";
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          val
            .toString()
            .split(",")
            .forEach((v) => {
              url += `&${key}=${v}`;
            });
        });
      } else url += `&${key}=${value}`;
    }
  }
  return axiosGet(url);
}

function create(data) {
  return axiosPost("/api/v1/categories/", data);
}

function detail(id) {
  return axiosGet(`/api/v1/categories/${id}/`);
}

function update(id, data) {
  return axiosPut(`/api/v1/categories/${id}/`, data);
}

function remove(id) {
  return axiosDelete(`/api/v1/categories/${id}/`);
}
