import { axiosDelete, axiosGet, axiosPost, axiosPatch, axiosPut, getSeparator, formatFilter } from "@/helpers";

export const carrierPricing = {
  list,
  create,
  detail,
  update,
  updatePartial,
  remove,
  findByCarrierAndAddresses,
};

const URL = "/api/v1/carrier-prices/";

function list(page, itemsPerPage, sort, addresses, carrier, expand, filter) {
  let url = URL;
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (carrier) {
    url += `${getSeparator(url)}carrier=${carrier}`;
  }
  if (expand) {
    url += `${getSeparator(url)}expand=${expand}`;
  }
  // @TODO to remove, seems not used..
  if (addresses) {
    for (let index = 0; index < addresses.length; ++index) {
      for (const [key, value] of Object.entries(addresses[index])) {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            val
              .toString()
              .split(",")
              .forEach((v) => {
                url += `&${key}=${v}`;
              });
          });
        } else url += `&${key}=${value}`;
      }
    }
  }

  url = formatFilter(url, filter);

  return axiosGet(url);
}

function create(data) {
  return axiosPost(URL, data);
}

function detail(id, expand) {
  if (expand) {
    return axiosGet(`${URL}${id}/?expand=${expand}`);
  }
  return axiosGet(`${URL}${id}/`);
}

function findByCarrierAndAddresses(carrier, pickupAddress, deliveryAddress) {
  let url = URL;

  if (carrier) {
    url += `${getSeparator(url)}carrier=${carrier}`;
  }

  if (pickupAddress) {
    url += `${getSeparator(url)}pickup_address=${pickupAddress}`;
  }

  if (deliveryAddress) {
    url += `${getSeparator(url)}delivery_address=${deliveryAddress}`;
  }

  return axiosGet(url);
}

function update(id, data) {
  return axiosPut(`${URL}${id}/`, data);
}

function updatePartial(id, data) {
  return axiosPatch(`${URL}${id}/`, data);
}

function remove(id) {
  return axiosDelete(`${URL}${id}/`);
}
