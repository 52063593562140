import { auth } from "./auth";
import { address } from "./address";
import { category } from "./category";
import { country } from "./country";
import { contactPerson } from "./contactPerson";
import { carrierInvoice } from "./carrierInvoice";
import { currency } from "./currency";
import { language } from "./language";
import { load } from "./load";
import { users } from "./users";
import { transportOrder } from "./transportOrder";
import { transportOrderStep } from "./transportOrderStep";
import { transportOrderStepLine } from "./transportOrderStepLine";
import { transportType } from "./transportType";
import { partners } from "./partner";
import { transport } from "./transport";
import { cargoSpace } from "./cargoSpace";
import { reports } from "./report";
import { carrierPricing } from "./carrierPricing";
import { virtualLoad } from "./virtualLoading";
import { file } from "./file";

export const api = {
  auth,
  address,
  category,
  cargoSpace,
  carrierPricing,
  carrierInvoice,
  country,
  contactPerson,
  currency,
  language,
  load,
  partners,
  reports,
  users,
  transport,
  transportType,
  transportOrder,
  transportOrderStep,
  transportOrderStepLine,
  virtualLoad,
  file,
};
