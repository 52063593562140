import Vue from "vue";
import { api } from "@/services";
import { getTempId, isEqual, isTempId } from "@/helpers";

export const TransportOrderStepLineStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const TransportOrderStepLineItemStatus = {
  NONE: "",
  CREATED: "created",
  UPDATED: "updated",
  REMOVED: "removed",
};

export const transportOrderStepLine = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    removedItems: [],
    status: TransportOrderStepLineStateStatus.NONE,
    error: {},
    totalItems: 0,
    choices: [],
  },
  getters: {
    getChoices: (state) => () => {
      return state.choices;
    },
    filterByGivenTransportOrderStepIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(item.transport_order_step));
      return selectedItems;
    },
    createdTransportOrderStepLines: (state) => (transportOrderStepId) => {
      return state.items.filter(
        (item) =>
          item.status === TransportOrderStepLineItemStatus.CREATED && item.transport_order_step === transportOrderStepId
      );
    },
    updatedTransportOrderStepLines: (state) => (transportOrderStepId) => {
      return state.items.filter(
        (item) =>
          item.status === TransportOrderStepLineItemStatus.UPDATED && item.transport_order_step === transportOrderStepId
      );
    },
    removedTransportOrderStepLines: (state) => (transportOrderStepId) => {
      return state.removedItems.filter((item) => item.transport_order_step === transportOrderStepId);
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStepLine
        .list(page, itemsPerPage, sort, search)
        .then((response) => {
          commit("SET_ITEMS", response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByTransportOrderStepId({ commit }, { transportOrderStepId }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStepLine
        .listByTransportOrderStep(transportOrderStepId)
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByTransportOrderLinesByTO({ commit }, { transportOrderId }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStepLine
        .listByTransportOrder(transportOrderId)
        .then((response) => {
          commit("SET_ITEMS", response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStepLine
        .create(object)
        .then((item) => {
          commit("ADD_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createLocal({ commit }, { object }) {
      commit("ADD_ITEM", object);
    },
    detail({ commit }, { id }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");
      return api.transportOrderStepLine
        .detail(id)
        .then((response) => {
          commit("SET_ITEM", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStepLine
        .update(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("UPDATE_ITEM", object);

      commit("UNSET_STATUS");
    },
    removeRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrderStep
        .remove(object.id)
        .then(() => {
          commit("REMOVE_ITEM", object);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    removeLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("REMOVE_ITEM", object);

      commit("UNSET_STATUS");
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetItem({ commit }) {
      commit("UNSET_ITEM");
    },
    unsetRemovedItems({ commit }) {
      commit("UNSET_REMOVED_ITEMS");
    },
    unsetAllItems({ commit }) {
      commit("UNSET_ALL_ITEMS");
    },
    addStepItem({ commit, state }, { stepId, kind }) {
      commit("UNSET_STATUS");

      let emptyItemWithStepId = {
        id: getTempId("transportOrderStepLine"),
        transport_order_step: stepId,
        kind: kind,
        line_number: state.items.filter((item) => item.transport_order_step === stepId).length + 1,
        partner: "",
        partner_contact_person: "",
        load: [],
        address: "",
        date: null,
        shipment_content: "Timber",
      };

      commit("ADD_ITEM", emptyItemWithStepId);
    },
    addEmptyItem({ commit }) {
      commit("UNSET_STATUS");

      let emptyItem = {
        id: getTempId("transportOrderStepLine"),
        transport_order_step: "",
        kind: "",
        line_number: "",
        partner: "",
        partner_contact_person: "",
        load: [],
        address: "",
        date: null,
        shipment_content: "Timber",
      };

      commit("ADD_ITEM", emptyItem);
    },
    getStatuses({ commit }) {
      commit("UNSET_STATUS");

      return api.transportOrderStepLine
        .getTransportOrderStepLineKinds()
        .then((choices) => commit("SET_CHOICES", choices.data))
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
  },
  mutations: {
    SET_CHOICES(state, choices) {
      let choicesList = [];
      for (let key in choices) {
        choicesList.push({ id: Number(key), name: choices[key] });
      }
      state.choices = choicesList;
    },
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ITEM(state) {
      state.item = {};
    },
    UNSET_REMOVED_ITEMS(state) {
      state.removedItems = [];
    },
    UNSET_ALL_ITEMS(state) {
      state.items = [];
      state.item = {};
      state.removedItems = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = TransportOrderStepLineStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = TransportOrderStepLineStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = TransportOrderStepLineStateStatus.NONE;
    },
    ADD_ITEM(state, item) {
      item.status = TransportOrderStepLineItemStatus.CREATED;
      state.items.push(item);
      state.totalItems = state.items.length;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_ITEMS(state, items) {
      state.items = items;
      state.totalItems = state.items.length;
    },
    UPDATE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let index = state.items.findIndex((stateItem) => isEqual(stateItem.id, item.id));
      if (index !== -1) {
        if (!isTempId(item.id)) {
          item.status = TransportOrderStepLineItemStatus.UPDATED;
        } else {
          item.status = state.items[index].status;
        }
        Vue.set(state.items, index, item);
      }
    },
    REMOVE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newStateItems = [];
      for (let index = 0; index < state.items.length; ++index) {
        let stateItem = state.items[index];
        if (isEqual(stateItem.id, item.id)) {
          if (!isTempId(stateItem.id)) {
            stateItem.status = TransportOrderStepLineItemStatus.REMOVED;
            state.removedItems.push(stateItem);
          }
        } else {
          newStateItems.push(stateItem);
        }
      }
      state.items = newStateItems;
      state.totalItems = state.items.length;
    },
  },
};

export default {
  transportOrderStepLine,
  TransportOrderStepLineStateStatus,
  TransportOrderStepLineItemStatus,
};
