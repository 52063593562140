import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
  axiosPatch,
  axiosDownload,
  getSeparator,
  formatFilter,
} from "@/helpers";

export const transportOrderStep = {
  list,
  listByCarrier,
  listRouteTable,
  create,
  detail,
  update,
  patch,
  remove,
  getTransportOrderStepStatuses,
  listByTransportOrder,
  swapCarriers,
  downloadRouteTableReport,
};

const URL = "/api/v1/transport-order-steps/";

function list(page, itemsPerPage, sort, search) {
  let url = URL;
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }

  return axiosGet(url);
}

function listRouteTable(page, itemsPerPage, sort, search, filter, carrierId) {
  let url = URL + "route_table/";
  url = formatRouteTableListUrl(url, page, itemsPerPage, sort, search, filter, carrierId);

  return axiosGet(url);
}

function downloadRouteTableReport(sort, filter) {
  const date = new Date();
  const [seconds, minutes, hour, day, month, year] = [
    ("0" + date.getSeconds()).slice(-2),
    ("0" + date.getMinutes()).slice(-2),
    ("0" + date.getHours()).slice(-2),
    ("0" + date.getDate()).slice(-2),
    ("0" + (date.getMonth() + 1)).slice(-2),
    date.getFullYear(),
  ];

  let dateForFileName = year + "-" + month + "-" + day + "_" + hour + minutes + seconds;
  let fileName = "route_table-" + dateForFileName + ".xlsx";

  let url = URL;
  url += "route_table_report/";
  url = formatRouteTableListUrl(url, "", "", sort, "", filter, "");

  axiosDownload({
    url: url,
    filename: fileName,
  });
}

function formatRouteTableListUrl(url, page, itemsPerPage, sort, search, filter, carrierId) {
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }
  if (carrierId) {
    url += `${getSeparator(url)}carrier=${carrierId}`;
  }

  url = formatFilter(url, filter);

  return url;
}

function create(data) {
  return axiosPost(URL, data);
}

function detail(id) {
  return axiosGet(`${URL}${id}/`);
}

function update(id, data) {
  return axiosPut(`${URL}${id}/`, data);
}

function patch(id, data) {
  return axiosPatch(`${URL}${id}/`, data);
}

function remove(id) {
  return axiosDelete(`${URL}${id}/`);
}

function getTransportOrderStepStatuses() {
  return axiosGet(`${URL}statuses/`);
}

function listByTransportOrder(transportOrderId, pageSize = 1000) {
  return axiosGet(`${URL}?transport_order=${transportOrderId}&page_size=${pageSize}&ordering=chain_number`);
}

function listByCarrier(carrierId, pageSize = 1000) {
  return axiosGet(`${URL}?carrier=${carrierId}&page_size=${pageSize}`);
}

function swapCarriers(data) {
  return axiosPost(`${URL}swap_carriers/`, data);
}
