import { api } from "@/services";
import { isEqual } from "@/helpers";

export const PartnerStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const partners = {
  namespaced: true,
  state: {
    items: [],
    partner: {},
    status: PartnerStateStatus.NONE,
    error: {},
    totalItems: 0,
    senders: [],
    recipients: [],
    carriers: [],
    itemsSmall: [],
  },
  getters: {
    getAddressByPartnerId: (state) => (id) => {
      let partner = state.items.find((item) => item.id === id);
      if (partner.address) {
        return partner.address;
      } else {
        return [];
      }
    },
    filterByGivenIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(item.id));
      return selectedItems;
    },
    getById: (state) => (id) => {
      return state.items.filter((item) => isEqual(item.id, id));
    },
    getByName: (state) => (name) => {
      return state.items.filter((item) => isEqual(item.name, name));
    },
  },
  actions: {
    list(
      { commit },
      { page, itemsPerPage, sort, search, expand, filterQuery, mutationAction = "GET_PAGINATED", commitResult = true }
    ) {
      commit("UNSET_ERROR");

      return api.partners
        .list(page, itemsPerPage, sort, search, expand, filterQuery)
        .then((partners) => {
          if (commitResult) {
            commit(`${mutationAction}`, partners.data);
          } else {
            return partners.data.results;
          }
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    listSmall(
      { commit },
      { page, itemsPerPage, sort, search, filterQuery, mutationAction = "GET_SMALL", commitResult = true }
    ) {
      commit("UNSET_ERROR");

      return api.partners
        .listSmall(page, itemsPerPage, sort, search, filterQuery)
        .then((partners) => {
          if (commitResult) {
            commit(`${mutationAction}`, partners.data);
          } else {
            return partners.data.results;
          }
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    setSmall({ commit }, { data }) {
      commit("SET_SMALL", { results: [data] });
    },
    listPartnersByPartialName({ commit }, { nameString, mutationAction }) {
      commit("UNSET_ERROR");

      return api.partners
        .listPartnersByPartialName(nameString)
        .then((partners) => commit(`${mutationAction}`, partners.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    listPartnersByRole({ commit }, { role, page, itemsPerPage, mutationAction }) {
      commit("UNSET_ERROR");

      return api.partners
        .listPartnersByRole(role, page, itemsPerPage)
        .then((partners) => commit(`${mutationAction}`, partners.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.partners
        .create(data)
        .then((partner) => commit("CREATE_PARTNER", partner.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit, state }, { id }) {
      let partnerLoaded = state.items.filter(function (element) {
        return element.id === id;
      });

      if (!partnerLoaded.length) {
        commit("UNSET_ERROR");
        commit("UNSET_PARTNER");

        return api.partners
          .detail(id)
          .then((partner) => commit("SET_PARTNER", partner.data))
          .catch((error) => commit("SET_ERROR", error));
      } else {
        commit("SET_PARTNER", partnerLoaded[0]);
      }
    },
    setFromLoaded({ commit }, { data }) {
      commit("UNSET_ERROR");
      commit("UNSET_PARTNER");
      commit("SET_PARTNER", data);
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.partners
        .update(id, data)
        .then((partner) => commit("UPDATE_PARTNER", partner.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.partners.remove(id).then(() => commit("DELETE_PARTNER", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetPartner({ commit }) {
      commit("UNSET_PARTNER");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = PartnerStateStatus.NONE;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = PartnerStateStatus.ERROR;
    },
    GET_PAGINATED(state, partners) {
      state.items = partners.results;
      state.totalItems = partners.count;
    },
    GET_SMALL(state, partners) {
      state.itemsSmall = partners.results;
    },
    SET_SMALL(state, partners) {
      Array.prototype.push.apply(state.itemsSmall, partners.results);
      state.itemsSmall = [...new Map(state.itemsSmall.map((item) => [item["id"], item])).values()];
    },
    SET_ITEMS_FROM_LIST(state, partners) {
      // firstly pushing all partners from response to store.
      Array.prototype.push.apply(state.items, partners.results);
      // filtering unique partners
      let filtered_inique_items = [...new Map(state.items.map((item) => [item["id"], item])).values()];
      // setting only unique partners
      state.items = filtered_inique_items;
      state.totalItems = state.items.count;
    },
    SET_ITEMS(state, partners) {
      // firstly pushing all partners from response to store.
      Array.prototype.push.apply(state.items, partners);
      // filtering unique partners
      let filtered_inique_items = [...new Map(state.items.map((item) => [item["id"], item])).values()];
      // setting only unique partners
      state.items = filtered_inique_items;
      state.totalItems = state.items.count;
    },
    SET_SENDERS(state, partners) {
      state.senders = partners;
    },
    SET_RECIPIENTS(state, partners) {
      state.recipients = partners;
    },
    SET_CARRIERS(state, partners) {
      state.carriers = partners;
    },
    SET_PARTNER(state, partner) {
      let partnerIsSet = state.items.filter(function (element) {
        return element.id === partner.id;
      });
      // adding partner to all items list in order to have the ability to map the selected partner.
      if (!partnerIsSet.length) {
        state.items.push(partner);
      }
      state.partner = partner;
    },
    UNSET_PARTNER(state) {
      state.partner = {};
    },
    UPDATE_PARTNER(state, partner) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (partner.id === state.items[i].id) {
            state.items[i] = partner;
            break;
          }
        }
      }
    },
    DELETE_PARTNER(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_PARTNER(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  partners,
  PartnerStateStatus,
};
