import { axiosGet, getSeparator, formatFilter } from "@/helpers";

export const reports = {
  carrierReport,
};

const BASE_URL = "/api/v1/reports/";

function carrierReport(page, itemsPerPage, sort, filter) {
  let url = `${BASE_URL}` + "carrier-route-report/";
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }

  url = formatFilter(url, filter);

  return axiosGet(url);
}
