import { api } from "@/services";

export const CategoryStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const category = {
  namespaced: true,
  state: {
    items: [],
    category: {},
    status: CategoryStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  getters: {
    getCategoryById: (state) => (id) => {
      let tt = state.items.find((item) => item.id.toString() === id.toString());
      if (tt) {
        return tt;
      }
      return {};
    },
    filterByGivenIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(item.id));
      return selectedItems;
    },
    getCategoryByName: (state) => (name) => {
      let cats = state.items.find((item) => item.name == name);
      if (cats) {
        return cats;
      }
      return {};
    },
  },
  actions: {
    list({ commit }, { page = 0, itemsPerPage = 1000, sort = null, search = null }) {
      commit("UNSET_ERROR");

      return api.category
        .list(page, itemsPerPage, sort, search)
        .then((categories) => commit("GET_PAGINATED", categories.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.category
        .create(data)
        .then((category) => commit("CREATE_CATEGORY", category.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.category
        .detail(id)
        .then((category) => commit("SET_CATEGORY", category.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.category
        .update(id, data)
        .then((category) => commit("UPDATE_CATEGORY", category.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.category.remove(id).then(() => commit("DELETE_CATEGORY", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetCategory({ commit }) {
      commit("UNSET_CATEGORY");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = CategoryStateStatus.NONE;
    },
    UNSET_CATEGORY(state) {
      state.transportType = {};
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = CategoryStateStatus.ERROR;
    },
    GET_PAGINATED(state, items) {
      state.items = items.results;
      state.totalItems = items.count;
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
    UPDATE_CATEGORY(state, category) {
      if (state.items) {
        for (let i = 0; i < state.items.length; ++i) {
          if (category.id === state.items[i].id) {
            state.items[i] = category;
            break;
          }
        }
      }
    },
    DELETE_CATEGORY(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_CATEGORY(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  category,
  CategoryStateStatus,
};
