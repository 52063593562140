import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPatch,
  axiosPut,
  axiosUpload,
  getSeparator,
  axiosDownload,
  formatFilter,
} from "@/helpers";

export const carrierInvoice = {
  list,
  create,
  detail,
  update,
  updatePartial,
  remove,
  getCarrierInvoiceSplitPrinciples,
  upload,
  listDocumentsByEntryId,
  deleteFile,
  downloadReport,
  clone,
};

const URL = "/api/v1/carrier-invoices/";

function list(page, itemsPerPage, sort, search, expand, filter) {
  let url = URL;
  url = formatListUrl(url, page, itemsPerPage, sort, search, expand, filter);

  return axiosGet(url);
}

function formatListUrl(url, page, itemsPerPage, sort, search, expand, filter) {
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }
  if (expand) {
    url += `${getSeparator(url)}expand=${expand}`;
  }

  url = formatFilter(url, filter);

  return url;
}

function downloadReport(sort, filter) {
  const date = new Date();
  const [seconds, minutes, hour, day, month, year] = [
    ("0" + date.getSeconds()).slice(-2),
    ("0" + date.getMinutes()).slice(-2),
    ("0" + date.getHours()).slice(-2),
    ("0" + date.getDate()).slice(-2),
    ("0" + (date.getMonth() + 1)).slice(-2),
    date.getFullYear(),
  ];

  let dateForFileName = year + "-" + month + "-" + day + "_" + hour + minutes + seconds;
  let fileName = "invoice_report-" + dateForFileName + ".xlsx";

  let url = URL;
  url += "download-report/";
  url = formatListUrl(url, "", "", sort, "", "", filter);

  axiosDownload({
    url: url,
    filename: fileName,
  });
}

function create(data) {
  return axiosPost(URL, data);
}

function detail(id, expand) {
  if (expand) {
    return axiosGet(`${URL}${id}/?expand=${expand}`);
  }
  return axiosGet(`${URL}${id}/`);
}

function update(id, data) {
  return axiosPut(`${URL}${id}/`, data);
}

function updatePartial(id, data) {
  return axiosPatch(`${URL}${id}/`, data);
}

function remove(id) {
  return axiosDelete(`${URL}${id}/`);
}

function getCarrierInvoiceSplitPrinciples() {
  return axiosGet(`${URL}split-principles/`);
}

function upload(id, data) {
  let formData = new FormData();
  data.forEach((file) => {
    formData.append("files", file);
  });
  return axiosUpload(`${URL}${id}/upload/`, formData);
}

function listDocumentsByEntryId(entryId) {
  return axiosGet(`${URL}${entryId}/documents/`);
}

function deleteFile(carrierInvoiceId, fileId) {
  return axiosDelete(`${URL}${carrierInvoiceId}/delete-file/${fileId}`);
}

function clone(invoiceId) {
  return axiosPost(`${URL}${invoiceId}/clone/`, {});
}
