import { api } from "@/services";
import router from "@/router";
import { USER_TOKEN_LS_KEY } from "@/config";

const userData = localStorage.getItem(USER_TOKEN_LS_KEY);
const initialState = userData ? { loggedIn: true, userData } : { loggedIn: false, user: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      api.auth.login(username, password).then(
        (user) => {
          if (user !== undefined) {
            commit("SET_LOGGED_IN", user);
            router.push("/");
          } else {
            dispatch("alert/error", "Something is wrong with authentication module", { root: true });
          }
        },
        (error) => {
          commit("SET_LOGOUT", error);
          dispatch("alert/error", error, { root: true });
        }
      );
    },
    logout({ commit }) {
      api.auth.logout();
      commit("SET_LOGOUT");
    },
  },
  mutations: {
    SET_LOGGED_IN(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    SET_LOGOUT(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
};
