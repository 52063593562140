import Vue from "vue";
import { api } from "@/services";
import { getTempId, isTempId, isEqual } from "@/helpers";

export const ContactPersonStateStatus = {
  NONE: "",
  ERROR: "error",
  LOADING: "loading",
};

const ContactPersonItemStatus = {
  NONE: "",
  CREATED: "created",
  UPDATED: "updated",
  REMOVED: "removed",
};

export const contactPerson = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    removedItems: [],
    status: ContactPersonStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  getters: {
    filterByGivenPartnerIds: (state) => (ids) => {
      let selectedItems = state.items.filter((item) => ids.includes(parseInt(item.partner)));
      return selectedItems;
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search, expand }) {
      commit("SET_STATUS_LOADING");

      return api.contactPerson
        .list(page, itemsPerPage, sort, search, expand)
        .then((contactPerson) => {
          commit("SET_ITEMS", contactPerson.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByPartnerId({ commit }, { partnerId }) {
      commit("UNSET_STATUS");
      commit("UNSET_ITEMS");
      return api.contactPerson
        .listByPartner(partnerId)
        .then((response) => {
          commit("SET_ITEMS", response.data);
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByIds({ commit }, { ids }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_STATUS");

      let items = [];
      ids.forEach((id) => {
        api.contactPerson
          .detail(id)
          .then((response) => items.push(response.data))
          .catch((error) => commit("SET_STATUS_ERROR", error));
      });
      commit("SET_ITEMS", items);
      commit("UNSET_STATUS");
    },
    addEmptyItem({ commit }) {
      commit("UNSET_ERROR");

      let emptyContactPerson = {
        id: getTempId("contactPerson"),
        partner: -1,
        name: "",
        email: "",
        phone: "",
        comment: "",
      };

      commit("ADD_ITEM", emptyContactPerson);
    },
    createRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.contactPerson
        .create(object)
        .then((item) => {
          commit("ADD_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },

    addItem({ commit }, { item }) {
      commit("UNSET_ERROR");
      commit("ADD_ITEM", item);
    },
    createLocal({ commit }, { object }) {
      commit("ADD_ITEM", object);
    },
    detail({ commit }, { id }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");

      return api.contactPerson
        .detail(id)
        .then((contactPerson) => commit("SET_CONTACT_PERSON", contactPerson.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    setFromLoaded({ commit }, { data }) {
      commit("ADD_ITEM", data);
    },
    updateRemote({ commit }, { id, data }) {
      commit("SET_STATUS_LOADING");

      if (!isTempId(id)) {
        return api.contactPerson
          .update(id, data)
          .then((contactPerson) => commit("UPDATE_ITEM", contactPerson.data))
          .catch((error) => commit("SET_ERROR", error));
      } else {
        commit("UPDATE_ITEM", data);
      }
    },
    updateLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("UPDATE_ITEM", object);

      commit("UNSET_STATUS");
    },
    removeRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.contactPerson
        .remove(object.id)
        .then(() => {
          commit("REMOVE_ITEM", object);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    removeLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("REMOVE_ITEM", object);

      commit("UNSET_STATUS");
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetItem({ commit }) {
      commit("UNSET_ITEM");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = ContactPersonStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = ContactPersonStateStatus.NONE;
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = ContactPersonStateStatus.ERROR;
    },
    SET_ITEMS(state, items) {
      state.items = items;
      state.totalItems = state.items.length;
    },
    ADD_ITEM(state, item) {
      item.status = ContactPersonItemStatus.CREATED;
      state.items.push(item);
      state.totalItems = state.items.length;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    UNSET_ITEM(state) {
      state.item = {};
    },
    UPDATE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let index = state.items.findIndex((stateItem) => isEqual(stateItem.id, item.id));
      if (index !== -1) {
        if (!isTempId(item.id)) {
          item.status = ContactPersonItemStatus.UPDATED;
        } else {
          item.status = state.items[index].status;
        }
        Vue.set(state.items, index, item);
      }
    },
    REMOVE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newStateItems = [];
      for (let index = 0; index < state.items.length; ++index) {
        let stateItem = state.items[index];
        if (isEqual(stateItem.id, item.id)) {
          if (!isTempId(stateItem.id)) {
            stateItem.status = ContactPersonItemStatus.REMOVED;
            state.removedItems.push(stateItem);
          }
        } else {
          newStateItems.push(stateItem);
        }
      }
      state.items = newStateItems;
      state.totalItems = state.items.length;
    },
    CREATE_CONTACT_PERSON(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  contactPerson,
  ContactPersonStateStatus,
};
