<template>
    <v-app id="app">
        <v-main>
            <v-container fluid>
                <div>
                    <v-app-bar dense flat style="margin-bottom: 7px">
                        <v-container class="hidden-md-and-down">
                            <v-tooltip v-for="item in menu" :key="item.path" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        text
                                        icon
                                        :title="item.title"
                                        :key="item.path"
                                        :to="item.path"
                                        @click="item.action"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon color="green">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.title }}</span>
                            </v-tooltip>
                        </v-container>
                        <v-spacer></v-spacer>
                        <v-menu left bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" class="hidden-lg-and-up">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item v-for="item in menu" :key="item.path" :to="item.path" @click="item.action">
                                    <v-list-item-title
                                        >{{ item.title }}
                                        <v-icon x-small dense color="green">{{ item.icon }}</v-icon></v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-toolbar-items>
                            <ThemeChanger />
                        </v-toolbar-items>
                    </v-app-bar>
                    <div v-if="alert.message">
                        <v-alert :v-if="alert.message" :type="alert.type"
                            >{{ alert.message }} <v-btn x-small text @click="clearAlerts">X</v-btn></v-alert
                        >
                    </div>
                </div>
                <router-view />
            </v-container>
        </v-main>

        <v-footer padless>
            <v-col class="text-center" cols="12"> <strong>OSOS</strong> © {{ new Date().getFullYear() }} </v-col>
            <div v-if="formatVersion()" style="position: absolute; bottom: 5px; left: 5px">
                <small style="opacity: 0.5">{{ formatVersion() }}</small>
            </div>
        </v-footer>
    </v-app>
</template>

<script>
import axios from "axios";
import ThemeChanger from "@/components/Generic/ThemeChanger";
import versionInfo from "../version.json";

export default {
    name: "App",
    components: {
        ThemeChanger,
    },
    data() {
        return {
            versionInfo: versionInfo,
        };
    },
    computed: {
        alert() {
            return this.$store.state.alert;
        },
        loggedIn() {
            return this.$store.state.authentication.loggedIn;
        },
        menu() {
            let menu = [
                { path: "/", title: "Home", icon: "mdi-home", action: () => {}, requiresLogin: undefined },
                //{ path: "/profile", title: "Profile", icon: "mdi-account", action: () => {}, requiresLogin: true },
                { path: "/users", title: "Users", icon: "mdi-account-group", action: () => {}, requiresLogin: true },
                { path: "/partners", title: "Partners", icon: "mdi-handshake", action: () => {}, requiresLogin: true },
                {
                    path: "/transports",
                    title: "Transports",
                    icon: "mdi-train-car",
                    action: () => {},
                    requiresLogin: true,
                },
                {
                    path: "/transport-orders",
                    title: "Transport orders",
                    icon: "mdi-cart-arrow-right",
                    action: () => {},
                    requiresLogin: true,
                },
                {
                    path: "/route-list",
                    title: "Route list",
                    icon: "mdi-routes",
                    action: () => {},
                    requiresLogin: true,
                },
                {
                    path: "/carrier-invoices",
                    title: "Carrier Invoices",
                    icon: "mdi-receipt",
                    action: () => {},
                    requiresLogin: true,
                },
                {
                    path: "/carrier-prices",
                    title: "Carrier Pricing",
                    icon: "mdi-cash-100",
                    action: () => {},
                    requiresLogin: true,
                },
                {
                    path: "/transport-types",
                    title: "Transport types",
                    icon: "mdi-bicycle",
                    action: () => {},
                    requiresLogin: true,
                },
                { path: "/categories", title: "Categories", icon: "mdi-shape", action: () => {}, requiresLogin: true },
                { path: "/loads", title: "Loads", icon: "mdi-upload-network", action: () => {}, requiresLogin: true },
                {
                    path: "/reports/carrier-route-report/",
                    title: "Reports",
                    icon: "mdi-text",
                    action: () => {},
                    requiresLogin: true,
                },
                {
                    path: "/virtual-loading/",
                    title: "Virtual Loading",
                    icon: "mdi-animation",
                    action: () => {},
                    requiresLogin: true,
                },
                //{ path: "/about", title: "About", icon: "mdi-information", action: () => {}, requiresLogin: undefined },
                { path: "/logout", title: "Logout", icon: "mdi-logout", action: this.logout, requiresLogin: true },
                { path: "/login", title: "Login", icon: "mdi-login", action: () => {}, requiresLogin: false },
            ];

            return menu.filter(
                (menuElement) => menuElement.requiresLogin === this.loggedIn || menuElement.requiresLogin === undefined
            );
        },
    },
    created: function () {
        // axios interceptor to logout the user if request is unauthorized.
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const { status } = error.response;
                if ([401, 403].includes(status)) {
                    this.logout();
                }
                return Promise.reject(error);
            }
        );
    },
    methods: {
        logout() {
            this.$store.dispatch("authentication/logout");
        },
        clearAlerts() {
            this.$store.dispatch("alert/clear");
        },
        formatVersion() {
            let info = "";
            let versionRawInfo = this.versionInfo;

            if (Object.keys(versionRawInfo).length) {
                if (versionRawInfo.date) {
                    info = versionRawInfo.date;
                }

                if (versionRawInfo.version) {
                    info = info + " (" + versionRawInfo.version + ")";
                } else if (versionRawInfo.hash) {
                    info = info + " (" + versionRawInfo.hash + ")";
                }
            }

            return info;
        },
    },
    watch: {
        $route() {
            // clear alert on location change
            this.$store.dispatch("alert/clear");
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn .v-icon:hover {
    transform: scale(1.5);
}
</style>
<style>
@media (min-width: 1264px) and (-webkit-min-device-pixel-ratio: 2) {
    .container {
        max-width: 100%;
    }
}
.v-card__title {
    word-break: normal !important;
}
.theme--light .table-link {
    color: rgba(0, 0, 0, 0.87) !important;
}

.table-link {
    color: #ffffff !important;
}
</style>
