import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    // ToDo: not found page?
    redirect: { name: "Home" },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/User/Profile.vue"),
  },
  {
    path: "/users",
    name: "UserList",
    component: () => import("@/views/User/UserList.vue"),
  },
  {
    path: "/users/:username",
    name: "UserDetail",
    props: true,
    component: () => import("@/views/User/UserDetail.vue"),
  },
  {
    path: "/users/~new",
    name: "UserNew",
    component: () => import("@/views/User/UserNew.vue"),
  },
  {
    path: "/users/:username/edit",
    name: "UserEdit",
    props: true,
    component: () => import("@/views/User/UserEdit.vue"),
  },
  {
    path: "/transports",
    name: "TransportList",
    component: () => import("@/views/Transport/TransportList.vue"),
  },
  {
    path: "/transports/~new",
    name: "TransportNew",
    component: () => import("@/views/Transport/TransportNew.vue"),
  },
  {
    path: "/transports/:id/edit",
    name: "TransportEdit",
    props: true,
    component: () => import("@/views/Transport/TransportEdit.vue"),
  },
  {
    path: "/transports/:id",
    name: "TransportDetail",
    props: true,
    component: () => import("@/views/Transport/TransportDetail.vue"),
  },
  {
    path: "/transport-types",
    name: "TransportTypeList",
    component: () => import("@/views/TransportType/TransportTypeList.vue"),
  },
  {
    path: "/transport-types/:id",
    name: "TransportTypeDetail",
    props: true,
    component: () => import("@/views/TransportType/TransportTypeDetail.vue"),
  },
  {
    path: "/transport-types/~new",
    name: "TransportTypeNew",
    component: () => import("@/views/TransportType/TransportTypeNew.vue"),
  },
  {
    path: "/transport-types/:id/edit",
    name: "TransportTypeEdit",
    props: true,
    component: () => import("@/views/TransportType/TransportTypeEdit.vue"),
  },
  {
    path: "/carrier-invoices",
    name: "CarrierInvoiceList",
    component: () => import("@/views/CarrierInvoice/CarrierInvoiceList.vue"),
  },
  {
    path: "/carrier-invoices/:id",
    name: "CarrierInvoiceDetail",
    props: true,
    component: () => import("@/views/CarrierInvoice/CarrierInvoiceDetail.vue"),
  },
  {
    path: "/carrier-invoices/~new",
    name: "CarrierInvoiceNew",
    component: () => import("@/views/CarrierInvoice/CarrierInvoiceDetail.vue"),
  },
  {
    path: "/carrier-invoices/:id/edit",
    name: "CarrierInvoiceEdit",
    props: true,
    component: () => import("@/views/CarrierInvoice/CarrierInvoiceDetail.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("@/views/Category/CategoryList.vue"),
  },
  {
    path: "/categories/:id/edit",
    name: "CategoryEdit",
    props: true,
    component: () => import("@/views/Category/CategoryDetail.vue"),
  },
  {
    path: "/categories/~new",
    name: "CategoryNew",
    component: () => import("@/views/Category/CategoryDetail.vue"),
  },
  {
    path: "/categories/:id",
    name: "CategoryDetail",
    props: true,
    component: () => import("@/views/Category/CategoryDetail.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("@/views/Partner/PartnerList.vue"),
  },
  {
    path: "/partners/:id/edit",
    name: "PartnerEdit",
    props: true,
    component: () => import("@/views/Partner/PartnerDetail.vue"),
  },
  {
    path: "/partners/~new",
    name: "PartnerNew",
    component: () => import("@/views/Partner/PartnerDetail.vue"),
  },
  {
    path: "/partners/:id",
    name: "PartnerDetail",
    props: true,
    component: () => import("@/views/Partner/PartnerDetail.vue"),
  },
  {
    path: "/loads",
    name: "Loads",
    component: () => import("@/views/Load/LoadList.vue"),
  },
  {
    path: "/loads/~new",
    name: "LoadNew",
    component: () => import("@/views/Load/LoadDetail.vue"),
  },
  {
    path: "/loads/:id",
    name: "LoadDetail",
    props: true,
    component: () => import("@/views/Load/LoadDetail.vue"),
  },
  {
    path: "/loads/:id/edit",
    name: "LoadEdit",
    props: true,
    component: () => import("@/views/Load/LoadDetail.vue"),
  },
  {
    path: "/transport-orders",
    name: "TransportOrders",
    component: () => import("@/views/TransportOrder/TransportOrderList.vue"),
  },
  {
    path: "/transport-orders/~new",
    name: "TransportOrderNew",
    props: true,
    component: () => import("@/views/TransportOrder/TransportOrderDetail.vue"),
  },
  {
    path: "/transport-orders/:id",
    name: "TransportOrderDetail",
    props: true,
    component: () => import("@/views/TransportOrder/TransportOrderDetail.vue"),
  },
  {
    path: "/transport-orders/:id/edit",
    name: "TransportOrderEdit",
    props: true,
    component: () => import("@/views/TransportOrder/TransportOrderDetail.vue"),
  },
  {
    path: "/route-list",
    name: "TransportOrderRouteList",
    component: () => import("@/views/TransportOrder/TransportOrderRouteList.vue"),
  },
  {
    path: "/reports/carrier-route-report/",
    name: "CarrierRouteReportView",
    component: () => import("@/views/Reports/CarrierRouteReportView.vue"),
  },
  {
    path: "/carrier-prices",
    name: "CarrierPricingList",
    component: () => import("@/views/CarrierPricing/CarrierPricingList.vue"),
  },
  {
    path: "/carrier-prices/~new",
    name: "CarrierPricingNew",
    component: () => import("@/views/CarrierPricing/CarrierPricingDetail.vue"),
  },
  {
    path: "/carrier-prices/:id",
    name: "CarrierPricingDetail",
    props: true,
    component: () => import("@/views/CarrierPricing/CarrierPricingDetail.vue"),
  },
  {
    path: "/carrier-prices/:id/edit",
    name: "CarrierPricingEdit",
    props: true,
    component: () => import("@/views/CarrierPricing/CarrierPricingDetail.vue"),
  },
  {
    path: "/virtual-loading/",
    name: "VirtualLoading",
    props: true,
    component: () => import("@/views/VirtualLoading/VirtualLoading.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/", "/about"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user-token");

  if (authRequired && !loggedIn) {
    next("/login");
  }

  next();
});

export default router;
