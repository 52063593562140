import { axiosPatch } from "@/helpers";

export const file = {
  updatePartial,
};

const URL = "/api/v1/files/";

function updatePartial(id, data) {
  return axiosPatch(`${URL}${id}/`, data)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}
