import Vue from "vue";
import { api } from "@/services";
import { getTempId, isEqual } from "@/helpers";
import { isTempId } from "../helpers";

export const CargoSpaceItemStatus = {
  NONE: "",
  CREATED: "created",
  UPDATED: "updated",
  REMOVED: "removed",
};

export const CargoSpaceStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const cargoSpace = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    removedItems: [],
    status: CargoSpaceStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  getters: {
    createdCargoSpaces: (state) => {
      return state.items.filter((item) => item.status === CargoSpaceItemStatus.CREATED);
    },
    updatedCargoSpaces: (state) => {
      return state.items.filter((item) => item.status === CargoSpaceItemStatus.UPDATED);
    },
    removedCargoSpaces: (state) => {
      return state.removedItems;
    },
  },
  actions: {
    listByTransportId({ commit }, { transportId }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ALL_ITEMS");
      return api.cargoSpace
        .listByTransport(transportId)
        .then((cargoSpaces) => {
          commit("SET_ITEMS", cargoSpaces.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },

    list({ commit }, { page, itemsPerPage, sort, search }) {
      commit("SET_STATUS_LOADING");

      return api.cargoSpace
        .list(page, itemsPerPage, sort, search)
        .then((response) => {
          commit("SET_ITEMS", response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByIds({ commit }, { ids }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEMS");

      let items = [];
      ids.forEach((id) => {
        api.cargoSpace
          .detail(id)
          .then((response) => items.push(response.data))
          .catch((error) => commit("SET_STATUS_ERROR", error));
      });

      commit("SET_ITEMS", items);
      commit("UNSET_STATUS");
    },
    detail({ commit }, { id }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");
      return api.cargoSpace
        .detail(id)
        .then((response) => {
          commit("SET_ITEM", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.cargoSpace
        .create(object)
        .then((item) => {
          commit("ADD_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createLocal({ commit }, { object }) {
      commit("ADD_ITEM", object);
    },
    updateRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.cargoSpace
        .update(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("UPDATE_ITEM", object);

      commit("UNSET_STATUS");
    },
    removeRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.cargoSpace
        .remove(object.id)
        .then(() => {
          commit("REMOVE_ITEM", object);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    removeLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("REMOVE_ITEM", object);

      commit("UNSET_STATUS");
    },
    addEmptyItem({ commit }) {
      commit("UNSET_STATUS");

      let emptyItem = {
        id: getTempId("cargoSpace"),
        name: "",
        length_limit: "",
        width_limit: "",
        height_limit: "",
        weight_limit: "",
      };

      commit("ADD_ITEM", emptyItem);
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetItem({ commit }) {
      commit("UNSET_ITEM");
    },
    unsetRemovedItems({ commit }) {
      commit("UNSET_REMOVED_ITEMS");
    },
    unsetAllItems({ commit }) {
      commit("UNSET_ALL_ITEMS");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ITEM(state) {
      state.item = {};
    },
    UNSET_REMOVED_ITEMS(state) {
      state.removedItems = [];
    },
    UNSET_ALL_ITEMS(state) {
      state.items = [];
      state.item = {};
      state.removedItems = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = CargoSpaceStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = CargoSpaceStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = CargoSpaceStateStatus.NONE;
    },
    ADD_ITEM(state, item) {
      item.status = CargoSpaceItemStatus.CREATED;
      state.items.push(item);
      state.totalItems = state.items.length;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_ITEMS(state, items) {
      state.items = items;
      state.totalItems = state.items.length;
    },
    UPDATE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let index = state.items.findIndex((stateItem) => isEqual(stateItem.id, item.id));
      if (index !== -1) {
        if (!isTempId(item.id)) {
          item.status = CargoSpaceItemStatus.UPDATED;
        } else {
          item.status = state.items[index].status;
        }
        Vue.set(state.items, index, item);
      }
    },
    REMOVE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newStateItems = [];
      for (let index = 0; index < state.items.length; ++index) {
        let stateItem = state.items[index];
        if (isEqual(stateItem.id, item.id)) {
          if (!isTempId(stateItem.id)) {
            stateItem.status = CargoSpaceItemStatus.REMOVED;
            state.removedItems.push(stateItem);
          }
        } else {
          newStateItems.push(stateItem);
        }
      }
      state.items = newStateItems;
      state.totalItems = state.items.length;
    },
  },
};

export default {
  cargoSpace,
  CargoSpaceStateStatus,
  CargoSpaceItemStatus,
};
