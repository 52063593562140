import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alert";
import { address } from "./address";
import { authentication } from "./authentication";
import { contactPerson } from "./contactPerson";
import { carrierInvoice } from "./carrierInvoice";
import { cargoSpace } from "./cargoSpace";
import { category } from "./category";
import { country } from "./country";
import { contactPersonV2 } from "./contactPersonV2";
import { currency } from "./currency";
import { loads } from "./load";
import { language } from "./language";
import { partners } from "./partner";
import { users } from "./users";
import { transport } from "./transport";
import { transportType } from "./transportType";
import { transportOrder } from "./transportOrder";
import { transportOrderStep } from "./transportOrderStep";
import { transportOrderStepLine } from "./transportOrderStepLine";
import { reports } from "./report";
import { carrierPricing } from "./carrierPricing";
import { virtualLoad } from "./virtualLoading";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    address,
    authentication,
    cargoSpace,
    carrierPricing,
    carrierInvoice,
    category,
    country,
    contactPersonV2,
    contactPerson,
    currency,
    loads,
    language,
    partners,
    users,
    transportType,
    transportOrder,
    transportOrderStep,
    transportOrderStepLine,
    transport,
    reports,
    virtualLoad,
  },
});
