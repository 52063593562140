import Vue from "vue";
import { api } from "@/services";
import { getTempId, isEqual, isTempId } from "@/helpers";

export const TransportOrderStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const TransportOrderItemStatus = {
  NONE: "",
  CREATED: "created",
  UPDATED: "updated",
  REMOVED: "removed",
};

export const transportOrder = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    clonedItem: {},
    removedItems: [],
    status: TransportOrderStateStatus.NONE,
    error: {},
    totalItems: 0,
    choices: [],
    documents: [],
  },
  getters: {
    getChoices: (state) => () => {
      return state.choices;
    },
    getStatusByName: (state) => (name) => {
      let status = state.choices.find((item) => item.name == name);
      if (status) {
        return status;
      }
      return {};
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrder
        .list(page, itemsPerPage, sort, search)
        .then((response) => {
          commit("SET_ITEMS", response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    resendContracts({ commit }, { id }) {
      commit("UNSET_STATUS");
      return api.transportOrder.resendContracts(id).catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listFlatTable({ commit }, { page, itemsPerPage, sort, search, filter }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEMS");

      return api.transportOrder
        .listFlatTable(page, itemsPerPage, sort, search, filter)
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listByIds({ commit }, { ids }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEMS");

      let items = [];
      ids.forEach((id) => {
        api.transportOrder
          .detail(id)
          .then((response) => items.push(response.data.results))
          .catch((error) => commit("SET_STATUS_ERROR", error));
      });

      commit("SET_ITEMS", items);
      commit("UNSET_STATUS");
    },
    createRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrder
        .create(object)
        .then((item) => {
          commit("ADD_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    createLocal({ commit }, { object }) {
      commit("ADD_ITEM", object);
    },
    detail({ commit }, { id }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEM");
      return api.transportOrder
        .detail(id)
        .then((response) => {
          commit("SET_ITEM", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrder
        .update(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    clone({ commit }, { object }) {
      commit("SET_STATUS_LOADING");
      return api.transportOrder
        .clone(object.id)
        .then((item) => {
          commit("SET_CLONED_ITEM", item.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateRemotePartial({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrder
        .updatePartial(object.id, object)
        .then((item) => {
          commit("UPDATE_ITEM", item);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    updateLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("UPDATE_ITEM", object);

      commit("UNSET_STATUS");
    },
    removeRemote({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      return api.transportOrder
        .remove(object.id)
        .then(() => {
          commit("REMOVE_ITEM", object);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    removeLocal({ commit }, { object }) {
      commit("SET_STATUS_LOADING");

      commit("REMOVE_ITEM", object);

      commit("UNSET_STATUS");
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetItem({ commit }) {
      commit("UNSET_ITEM");
    },
    unsetRemovedItems({ commit }) {
      commit("UNSET_REMOVED_ITEMS");
    },
    unsetAllItems({ commit }) {
      commit("UNSET_ALL_ITEMS");
    },
    addEmptyItem({ commit }) {
      commit("UNSET_STATUS");

      let emptyItem = {
        id: getTempId("transportOrder"),
        code: "",
        status: "",
        load: [],
      };

      commit("ADD_ITEM", emptyItem);
    },
    getStatuses({ commit }) {
      commit("UNSET_STATUS");

      return api.transportOrder
        .getTransportOrderStatuses()
        .then((choices) => commit("SET_CHOICES", choices.data))
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    upload({ commit }, { id, files }) {
      commit("UNSET_STATUS");

      return api.transportOrder
        .upload(id, files)
        .then((response) => {
          commit("ADD_DOCUMENTS", response.data);
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    downloadFiles({ commit }, { transportOrderId, fileId }) {
      commit("UNSET_STATUS");

      return api.transportOrder
        .downloadFiles(transportOrderId, fileId)
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    listDocumentsByEntryId({ commit }, { entryId }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_DOCUMENTS");

      return api.transportOrder
        .listDocumentsByEntryId(entryId)
        .then((response) => {
          commit("SET_DOCUMENTS", response.data.results);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    unsetDocuments({ commit }) {
      commit("UNSET_DOCUMENTS");
    },
  },
  mutations: {
    SET_CHOICES(state, choices) {
      let choicesList = [];
      for (let key in choices) {
        choicesList.push({ id: Number(key), name: choices[key] });
      }
      state.choices = choicesList;
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    ADD_DOCUMENTS(state, docs) {
      docs.forEach((item) => state.documents.push(item));
    },
    UNSET_DOCUMENTS(state) {
      state.documents = [];
    },
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ITEM(state) {
      state.item = {};
    },
    UNSET_REMOVED_ITEMS(state) {
      state.removedItems = [];
    },
    UNSET_ALL_ITEMS(state) {
      state.items = [];
      state.item = {};
      state.clonedItem = {};
      state.removedItems = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = TransportOrderStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = TransportOrderStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = TransportOrderStateStatus.NONE;
    },
    ADD_ITEM(state, item) {
      item.status = TransportOrderItemStatus.CREATED;
      state.items.push(item);
      state.totalItems = state.items.length;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_CLONED_ITEM(state, item) {
      state.clonedItem = item;
    },
    SET_ITEMS(state, items) {
      state.items = items.results;
      state.totalItems = items.count;
    },
    UPDATE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let index = state.items.findIndex((stateItem) => isEqual(stateItem.id, item.id));
      if (index !== -1) {
        if (!isTempId(item.id)) {
          item.status = TransportOrderItemStatus.UPDATED;
        } else {
          item.status = state.items[index].status;
        }
        Vue.set(state.items, index, item);
      }
    },
    REMOVE_ITEM(state, item) {
      if (!state.items) {
        return;
      }
      let newStateItems = [];
      for (let index = 0; index < state.items.length; ++index) {
        let stateItem = state.items[index];
        if (isEqual(stateItem.id, item.id)) {
          if (!isTempId(stateItem.id)) {
            stateItem.status = TransportOrderItemStatus.REMOVED;
            state.removedItems.push(stateItem);
          }
        } else {
          newStateItems.push(stateItem);
        }
      }
      state.items = newStateItems;
      state.totalItems = state.items.length;
    },
  },
};

export default {
  transportOrder,
  TransportOrderStateStatus,
  TransportOrderItemStatus,
};
