//* api url used for communication with backend.
export const API_URL = getApiURL();

export function getApiURL() {
  let envUrl = process.env.VUE_APP_API_URL;
  if (!envUrl) {
    return "http://localhost:8000/";
  }
  return envUrl;
}

//* user token key for local storage.
export const USER_TOKEN_LS_KEY = process.env.USER_TOKEN_LS_KEY || "user-token";
