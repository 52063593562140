import { api } from "@/services";

export const LoadStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const loads = {
  namespaced: true,
  state: {
    items: [],
    unassigned_items: [],
    load: {},
    status: LoadStateStatus.NONE,
    error: {},
    totalItems: 0,
    choices: [],
  },
  getters: {
    getChoices: (state) => () => {
      let choices = state.choices;
      return choices;
    },
    filterByGivenIds: (state) => (ids) => {
      let allLoads = [...state.unassigned_items, ...state.items];

      let unique_items = [...new Map(allLoads.map((item) => [item["id"], item])).values()];

      return unique_items.filter((item) => ids.includes(item.id));
    },
    filterByCategoryId: (state) => (id, checkInUnassignedItems) => {
      if (checkInUnassignedItems) {
        if (!state.unassigned_items) {
          return [];
        } else {
          return state.unassigned_items.filter((item) => item.category.includes(id));
        }
      }
      if (!state.items) {
        return [];
      }
      return state.items.filter((item) => item.category.includes(id));
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search, expand, filter }) {
      commit("UNSET_ERROR");

      return api.load
        .list(page, itemsPerPage, sort, search, expand, filter)
        .then((loads) => commit("GET_PAGINATED", loads.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    listUnassignedLoads({ commit }, { filter, expand }) {
      commit("UNSET_ERROR");

      return api.load
        .listUnassignedLoads(filter, expand)
        .then((loads) => commit("SET_UNASSGINED_ITEMS", loads.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    listByTransportOrder({ commit }, { page, itemsPerPage, sort, search, expand, filter, transportOrderId }) {
      commit("UNSET_ERROR");

      return api.load
        .listByTransportOrder(page, itemsPerPage, sort, search, expand, filter, transportOrderId)
        .then((loads) => commit("GET_PAGINATED", loads.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.load
        .create(data)
        .then((load) => commit("CREATE_LOAD", load.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    clone({ commit }, { object }) {
      commit("UNSET_ERROR");
      return api.load
        .clone(object.id)
        .then((load) => {
          commit("CREATE_LOAD", load.data);
          return load.data;
        })
        .catch((error) => commit("SET_ERROR", error));
    },
    addToListFromLocal({ commit, state }, { data }) {
      let loadIsSet = state.items.filter(function (element) {
        return element.id === data.id;
      });

      if (!loadIsSet.length) {
        commit("CREATE_LOAD", data);
      }
    },
    detail({ commit, state }, { id, expand }) {
      if (!state.load || state.load.id !== id) {
        commit("UNSET_ERROR");
        commit("UNSET_LOAD");

        return api.load
          .detail(id, expand)
          .then((load) => commit("SET_LOAD", load.data))
          .catch((error) => commit("SET_ERROR", error));
      }
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.load
        .update(id, data)
        .then((load) => commit("UPDATE_LOAD", load.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    updatePartial({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.load
        .updatePartial(id, data)
        .then((load) => commit("UPDATE_LOAD", load.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.load.remove(id).then(() => commit("DELETE_LOAD", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetLoad({ commit }) {
      commit("UNSET_LOAD");
    },
    getStatuses({ commit }) {
      commit("UNSET_ERROR");

      return api.load
        .getLoadStatuses()
        .then((choices) => commit("SET_CHOICES", choices.data))
        .catch((error) => commit("SET_ERROR", error));
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = LoadStateStatus.NONE;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = LoadStateStatus.ERROR;
    },
    GET_PAGINATED(state, loads) {
      state.items = loads.results;
      state.totalItems = loads.count;
    },
    SET_ITEMS(state, loads) {
      state.items = loads;
    },
    SET_UNASSGINED_ITEMS(state, loads) {
      state.unassigned_items = loads;
    },
    SET_LOAD(state, load) {
      state.load = load;
    },
    UNSET_LOAD(state) {
      state.load = {};
    },
    UPDATE_LOAD(state, load) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (load.id === state.items[i].id) {
            state.items[i] = load;
            break;
          }
        }
      }
    },
    DELETE_LOAD(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_LOAD(state, data) {
      state.items.push(data);
      state.status = "";
    },
    SET_CHOICES(state, choices) {
      let choicesList = [];
      for (let key in choices) {
        choicesList.push({ id: Number(key), name: choices[key] });
      }
      state.choices = choicesList;
    },
  },
};

export default {
  loads,
  LoadStateStatus,
};
