import { axiosGet } from "@/helpers";

export const currency = {
  list,
};

const URL = "/api/v1/constants/currencies/";

function list() {
  return axiosGet(URL);
}
