import { axiosDelete, axiosGet, axiosPost, axiosPut } from "@/helpers";

export const users = {
  list,
  create,
  detail,
  profile,
  update,
  remove,
};

function list(page, itemsPerPage, sort, search) {
  let url = "/api/v1/users/";
  if (page) {
    url += `?page=${page}&page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `&ordering=${sort}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return axiosGet(url);
}

function create(data) {
  return axiosPost("/api/v1/users/", data);
}

function detail(username) {
  return axiosGet(`/api/v1/users/${username}/`);
}

function profile() {
  return detail("me");
}

function update(username, data) {
  return axiosPut(`/api/v1/users/${username}/`, data);
}

function remove(username) {
  return axiosDelete(`/api/v1/users/${username}/`);
}
