import { axiosUpload } from "@/helpers";

export const virtualLoad = {
  upload,
};

const URL = "/api/v1/virtual-loading/virtual_loading/";

function upload(cargo_space_id, data) {
  let formData = new FormData();
  formData.append("cargo_space_id", cargo_space_id);
  data.forEach((file) => {
    formData.append("file", file);
  });
  return axiosUpload(URL, formData);
}
