import { axiosGet } from "@/helpers";

export const country = {
  list,
};

const URL = "/api/v1/constants/countries/";

function list() {
  return axiosGet(URL);
}
