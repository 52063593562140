import axios from "axios";
import { USER_TOKEN_LS_KEY } from "@/config";

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function authHeader() {
  let userToken = localStorage.getItem(USER_TOKEN_LS_KEY);
  if (userToken) {
    return {
      Authorization: "Token " + userToken,
      "X-CSRFToken": getCookie(axios.defaults.xsrfCookieName),
      "content-type": "application/json",
      "Accept-Language": "lt,en-US;q=0.8,en;q=0.6,ru;q=0.4,pl;q=0.2",
    };
  } else {
    return {};
  }
}

export function authHeaderMultiPart() {
  let userToken = localStorage.getItem(USER_TOKEN_LS_KEY);
  if (userToken) {
    return {
      Authorization: "Token " + userToken,
      "X-CSRFToken": getCookie(axios.defaults.xsrfCookieName),
      "content-type": "multipart/form-data",
    };
  } else {
    return {};
  }
}

export function axiosOptionsForMultiPart() {
  return {
    headers: authHeaderMultiPart(),
  };
}

export async function axiosUpload(url, data, options = axiosOptionsForMultiPart()) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function axiosOptions() {
  return {
    headers: authHeader(),
  };
}

export async function axiosGet(url, options = axiosOptions()) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function axiosPost(url, data, options = axiosOptions()) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } // else seems to be aborted - what todo with it?
      });
  });
}

export async function axiosPut(url, data, options = axiosOptions()) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function axiosPatch(url, data, options = axiosOptions()) {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function axiosDelete(url, options = axiosOptions()) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function axiosDownload(requestData, options = axiosOptions()) {
  return new Promise((resolve, reject) => {
    options.responseType = "arraybuffer";
    axios
      .get(requestData.url, options)
      .then((response) => {
        resolve(response);
        const link = document.createElement("a");
        let contentType = response.headers["content-type"];
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        link.setAttribute("download", requestData.filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
