import { api } from "@/services";

export const TransportTypeStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const transportType = {
  namespaced: true,
  state: {
    items: [],
    transportType: {},
    status: TransportTypeStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  getters: {
    getTransportTypeById: (state) => (id) => {
      let tt = state.items.find((item) => item.id.toString() === id.toString());
      if (tt) {
        return tt;
      }
      return {};
    },
  },
  actions: {
    list({ commit }, { page = 0, itemsPerPage = 1000, sort = null, search = null }) {
      commit("UNSET_ERROR");

      return api.transportType
        .list(page, itemsPerPage, sort, search)
        .then((transportTypes) => commit("GET_PAGINATED", transportTypes.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.transportType
        .create(data)
        .then((transportType) => commit("CREATE_TRANSPORT_TYPE", transportType.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit }, { id }) {
      commit("UNSET_ERROR");
      commit("UNSET_TRANSPORT_TYPE");

      return api.transportType
        .detail(id)
        .then((transportType) => commit("SET_TRANSPORT_TYPE", transportType.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.transportType
        .update(id, data)
        .then((transportType) => commit("UPDATE_TRANSPORT_TYPE", transportType.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.transportType.remove(id).then(() => commit("DELETE_TRANSPORT_TYPE", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetTransportType({ commit }) {
      commit("UNSET_TRANSPORT_TYPE");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = TransportTypeStateStatus.NONE;
    },
    UNSET_TRANSPORT_TYPE(state) {
      state.transportType = {};
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = TransportTypeStateStatus.ERROR;
    },
    GET_PAGINATED(state, transportTypes) {
      state.items = transportTypes.results;
      state.totalItems = transportTypes.count;
    },
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_TRANSPORT_TYPE(state, transportType) {
      state.transportType = transportType;
    },
    UPDATE_TRANSPORT_TYPE(state, transport_type) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (transport_type.id === state.items[i].id) {
            state.items[i] = transport_type;
            break;
          }
        }
      }
    },
    DELETE_TRANSPORT_TYPE(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_TRANSPORT_TYPE(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  transportType,
  TransportTypeStateStatus,
};
