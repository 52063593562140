import { axiosDelete, axiosGet, axiosPost, axiosPut, axiosPatch, getSeparator, formatFilter } from "@/helpers";

export const load = {
  list,
  create,
  clone,
  detail,
  update,
  updatePartial,
  remove,
  getLoadStatuses,
  listByTransportOrder,
  listUnassignedLoads,
};

function list(page, itemsPerPage, sort, search, expand, filter) {
  let url = "/api/v1/loads/";
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }
  if (expand) {
    url += `${getSeparator(url)}expand=${expand}`;
  }
  url = formatFilter(url, filter);

  return axiosGet(url);
}

function create(data) {
  return axiosPost("/api/v1/loads/", data);
}

function clone(loadId) {
  let url = "/api/v1/loads/";
  return axiosPost(`${url}${loadId}/clone/`, {});
}

function detail(id, expand) {
  let url = `/api/v1/loads/${id}/`;

  if (expand) {
    url += `?expand=${expand}`;
  }

  return axiosGet(url);
}

function update(id, data) {
  return axiosPut(`/api/v1/loads/${id}/`, data);
}

function updatePartial(id, data) {
  return axiosPatch(`/api/v1/loads/${id}/`, data);
}

function remove(id) {
  return axiosDelete(`/api/v1/loads/${id}/`);
}

function getLoadStatuses() {
  return axiosGet("/api/v1/loads/statuses/");
}

function listByTransportOrder(transprotOrderId) {
  return axiosGet(`/api/v1/loads/?transport_order=${transprotOrderId}`);
}

function listUnassignedLoads(filter, expand) {
  let url = "/api/v1/loads/get_unassigned_loads/";
  url = formatFilter(url, filter);

  if (expand) {
    url += `${getSeparator(url)}expand=${expand}`;
  }

  return axiosGet(url);
}
