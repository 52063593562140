import { axiosGet } from "@/helpers";

export const language = {
  list,
};

const URL = "/api/v1/constants/languages/";

function list() {
  return axiosGet(URL);
}
