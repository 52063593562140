import { api } from "@/services";

export const TransportStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const transport = {
  namespaced: true,
  state: {
    items: [],
    status: TransportStateStatus.NONE,
    transport: {},
    error: {},
    totalItems: 0,
  },
  getters: {
    filterByGivenIds: (state) => (ids) => {
      let selectedLoads = state.items.filter((item) => ids.includes(item.id));
      return selectedLoads;
    },
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search }) {
      commit("UNSET_ERROR");

      return api.transport
        .list(page, itemsPerPage, sort, search)
        .then((transport) => commit("GET_PAGINATED", transport.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit }, { id }) {
      commit("UNSET_ERROR");
      commit("UNSET_TRANSPORT");

      return api.transport
        .detail(id)
        .then((transport) => commit("SET_TRANSPORT", transport.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.transport
        .create(data)
        .then((transport) => commit("CREATE_TRANSPORT", transport.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    update({ commit }, { id, data }) {
      commit("UNSET_ERROR");

      return api.transport
        .update(id, data)
        .then((transport) => commit("UPDATE_TRANSPORT", transport.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { id }) {
      commit("UNSET_ERROR");

      return api.transport.remove(id).then(() => commit("DELETE_TRANSPORT", id));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetTransport({ commit }) {
      commit("UNSET_TRANSPORT");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = TransportStateStatus.NONE;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = TransportStateStatus.ERROR;
    },
    GET_PAGINATED(state, transport) {
      state.items = transport.results;
      state.totalItems = transport.count;
    },
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_TRANSPORT(state, transportData) {
      state.transport = transportData;
    },
    UNSET_TRANSPORT(state) {
      state.transport = {};
    },
    UPDATE_TRANSPORT(state, transport) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (transport.id === state.items[i].id) {
            state.items[i] = transport;
            break;
          }
        }
      }
    },
    DELETE_TRANSPORT(state, id) {
      if (state.items) {
        state.items = state.items.filter((value) => value.id !== id);
      }
    },
    CREATE_TRANSPORT(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  transport,
  TransportStateStatus,
};
