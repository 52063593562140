import { api } from "@/services";

export const VirtualLoadingStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const virtualLoad = {
  namespaced: true,
  state: {
    fittedItems: [],
    unfittedItems: [],
    loadingSpace: [],
    status: VirtualLoadingStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  actions: {
    upload({ commit }, { cargo_space_id, file }) {
      commit("UNSET_STATUS");

      return api.virtualLoad
        .upload(cargo_space_id, file)
        .then((response) => {
          commit("SET_ITEMS", response.data);
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.fittedItems = data[0].fitted_items;
      state.unfittedItems = data[0].unfitted_items;
      delete data[0].fitted_items;
      delete data[0].unfitted_items;
      state.loadingSpace = data;
    },
    UNSET_ITEMS(state) {
      state.fittedItems = [];
      state.unfittedItems = [];
      state.loadingSpace = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = VirtualLoadingStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = VirtualLoadingStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = VirtualLoadingStateStatus.NONE;
    },
  },
};

export default {
  virtualLoad,
  VirtualLoadingStateStatus,
};
