export const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null,
  },
  actions: {
    success({ commit }, message) {
      commit("SET_SUCCESS", message);
    },
    error({ commit }, message) {
      commit("SET_ERROR", message);
    },
    warning({ commit }, message) {
      commit("SET_WARNING", message);
    },
    clear({ commit }) {
      commit("CLEAR");
    },
  },
  mutations: {
    SET_SUCCESS(state, message) {
      state.type = "success";
      state.message = message;
    },
    SET_ERROR(state, message) {
      state.type = "error";
      state.message = message;
    },
    SET_WARNING(state, message) {
      state.type = "warning";
      state.message = message;
    },
    CLEAR(state) {
      state.type = null;
      state.message = null;
    },
  },
};
