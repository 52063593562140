import { api } from "@/services";

export const UserStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const users = {
  namespaced: true,
  state: {
    items: [],
    user: {},
    profile: {},
    status: UserStateStatus.NONE,
    error: {},
    totalUsers: 0,
  },
  actions: {
    list({ commit }, { page, itemsPerPage, sort, search }) {
      commit("UNSET_ERROR");

      return api.users
        .list(page, itemsPerPage, sort, search)
        .then((users) => commit("GET_PAGINATED", users.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    create({ commit }, { data }) {
      commit("UNSET_ERROR");

      return api.users
        .create(data)
        .then((user) => commit("CREATE_USER", user.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    detail({ commit }, { username }) {
      commit("UNSET_ERROR");
      commit("UNSET_USER");

      return api.users
        .detail(username)
        .then((user) => commit("SET_USER", user.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    profile({ commit }) {
      commit("UNSET_ERROR");

      return api.users
        .profile()
        .then((profile) => commit("SET_PROFILE", profile.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    update({ commit }, { username, data }) {
      commit("UNSET_ERROR");

      return api.users
        .update(username, data)
        .then((user) => commit("UPDATE_USER", user.data))
        .catch((error) => commit("SET_ERROR", error));
    },
    remove({ commit }, { username }) {
      commit("UNSET_ERROR");

      return api.users.remove(username).then(() => commit("DELETE_USER", username));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
    unsetUser({ commit }) {
      commit("UNSET_USER");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = UserStateStatus.NONE;
    },
    UNSET_USER(state) {
      state.user = {};
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = UserStateStatus.ERROR;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    GET_PAGINATED(state, users) {
      state.items = users.results;
      state.totalUsers = users.count;
    },
    SET_ITEMS(state, users) {
      state.items = users;
    },
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
    UPDATE_USER(state, user) {
      if (state.items) {
        //* ToDo: figure out how we should handle this.
        for (let i = 0; i < state.items.length; ++i) {
          if (user.username === state.items[i].username) {
            state.items[i] = user;
            break;
          }
        }
      }
    },
    DELETE_USER(state, username) {
      if (state.items) {
        state.items = state.items.filter((value) => value.username !== username);
      }
    },
    CREATE_USER(state, data) {
      state.items.push(data);
      state.status = "";
    },
  },
};

export default {
  users,
  UserStateStatus,
};
