import { axiosDelete, axiosGet, axiosPost, axiosPut, getSeparator } from "@/helpers";

export const partners = {
  list,
  listSmall,
  create,
  detail,
  update,
  remove,
  listPartnersByPartialName,
  listPartnersByRole,
};

function list(page, itemsPerPage, sort, search, expand, filterQuery) {
  let url = "/api/v1/partners/";
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }
  if (expand) {
    url += `${getSeparator(url)}expand=${expand}`;
  }
  if (filterQuery) {
    url += `${getSeparator(url)}${filterQuery}`;
  }
  return axiosGet(url);
}

function listSmall(page, itemsPerPage, sort, search, filterQuery) {
  let url = "/api/v1/partners/small/";
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}name_contains=${search}`;
  }
  if (filterQuery) {
    url += `${getSeparator(url)}${filterQuery}`;
  }
  return axiosGet(url);
}

function create(data) {
  return axiosPost("/api/v1/partners/", data);
}

function detail(id) {
  return axiosGet(`/api/v1/partners/${id}/?expand=transport_type`);
}

function update(id, data) {
  return axiosPut(`/api/v1/partners/${id}/`, data);
}

function remove(id) {
  return axiosDelete(`/api/v1/partners/${id}/`);
}

function listPartnersByPartialName(nameString) {
  if (nameString) {
    return axiosGet(`/api/v1/partners/get_partner_from_partial_name/${nameString}/`);
  }
}

function listPartnersByRole(role, page, itemsPerPage) {
  if (role) {
    return axiosGet(`/api/v1/partners/get_partners_by_role/${role}/?page=${page}&page_size=${itemsPerPage}`);
  }
}
