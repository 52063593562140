import { axiosDelete, axiosGet, axiosPost, axiosPut, getSeparator } from "@/helpers";

export const address = {
  list,
  create,
  detail,
  update,
  remove,
};

function list(page, itemsPerPage, sort, search) {
  let url = "/api/v1/addresses/";
  if (page) {
    url += `${getSeparator(url)}page=${page}`;
  }
  if (itemsPerPage) {
    url += `${getSeparator(url)}page_size=${itemsPerPage}`;
  }
  if (sort) {
    url += `${getSeparator(url)}ordering=${sort}`;
  }
  if (search) {
    url += `${getSeparator(url)}search=${search}`;
  }
  return axiosGet(url);
}

function create(data) {
  return axiosPost("/api/v1/addresses/", data);
}

function detail(id) {
  return axiosGet(`/api/v1/addresses/${id}/`);
}

function update(id, data) {
  return axiosPut(`/api/v1/addresses/${id}/`, data);
}

function remove(id) {
  return axiosDelete(`/api/v1/addresses/${id}/`);
}
