import { axiosPost } from "@/helpers";
import { USER_TOKEN_LS_KEY } from "@/config";

export const auth = {
  login,
  logout,
};

function login(username, password) {
  return axiosPost("/api/auth/token/login", {
    username: username,
    password: password,
  })
    .then((response) => {
      if (response.data && response.data.auth_token) {
        localStorage.setItem(USER_TOKEN_LS_KEY, response.data.auth_token);
        return response;
      }
      return response;
    })
    .catch((error) => console.error("login error:", error));
}

function logout() {
  axiosPost("/api/auth/token/logout", {});
  localStorage.removeItem(USER_TOKEN_LS_KEY);
  location.reload();
}
