export function getSeparator(url) {
  if (url.indexOf("?") === -1) {
    return "?";
  } else {
    return "&";
  }
}

export function getStatusName(statuses, value) {
  let object = statuses.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
  return object[value];
}

export function getTempId(metaName = "") {
  return `temp-${metaName}-${Math.floor(Math.random() * Date.now())}`;
}

export function isTempId(id) {
  return id && id.toString().startsWith("temp-");
}

export function isEqual(value1, value2) {
  if (!value1 || !value2) {
    return false;
  }
  return value1.toString() === value2.toString();
}

export function formatListOfStrings(value) {
  let formatted_str = value.join(",\n");
  return formatted_str;
}

export function saveOrGetFilter(componentName, filter) {
  let filterFromStorage = localStorage.getItem(componentName);
  if (Object.keys(filter).length > 0 && filterFromStorage != null) {
    let parsedFilterFromServer = JSON.parse(filterFromStorage);
    for (let key in filter) {
      parsedFilterFromServer[key] = filter[key];
    }
    localStorage.setItem(componentName, JSON.stringify(parsedFilterFromServer));
    let result = JSON.parse(localStorage.getItem(componentName));
    return result;
  } else if (Object.keys(filter).length > 0 && filterFromStorage === null) {
    localStorage.setItem(componentName, JSON.stringify(filter));
    let result = JSON.parse(localStorage.getItem(componentName));
    return result;
  } else if (Object.keys(filter).length === 0 && filterFromStorage != null) {
    let result = JSON.parse(localStorage.getItem(componentName));
    return result;
  }
  return {};
}

export function formatFilter(url, filter) {
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (val && typeof val === "object") {
            if (val.id) {
              url += `${getSeparator(url)}${key}=${val.id}`;
            }
          } else {
            val
              .toString()
              .split(",")
              .forEach((v) => {
                url += `${getSeparator(url)}${key}=${v}`;
              });
          }
        });
      } else if (value && typeof value === "object") {
        if (value.id) {
          url += `${getSeparator(url)}${key}=${value.id}`;
        }
      } else if (value != null && value !== "") {
        url += `${getSeparator(url)}${key}=${value}`;
      }
    }
  }

  return url;
}

export function removeFilter(componentName) {
  localStorage.removeItem(componentName);
}

export function castToInt(object, props) {
  props.forEach((attribute) => {
    let val = object[attribute];

    if (val) {
      object[attribute] = Number(val).toFixed(0);
    }
  });
}

export function checkForNullable(object, props) {
  props.forEach((attribute) => {
    if (!object[attribute]) {
      object[attribute] = null;
    }
  });
}

export function saveOrGetPerPage(name, perPage) {
  let perPgeFromStorage = localStorage.getItem(name);
  let perPageToShow = 25;

  if (!perPage && perPgeFromStorage !== null) {
    perPageToShow = parseInt(perPgeFromStorage);
  } else if (perPage) {
    localStorage.setItem(name, perPage);
    perPageToShow = perPage;
  }

  return perPageToShow;
}
