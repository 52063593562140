import { api } from "@/services";

export const ReportStateStatus = {
  NONE: "",
  LOADING: "loading",
  ERROR: "error",
};

export const reports = {
  namespaced: true,
  state: {
    carrierReportItems: [],
    status: ReportStateStatus.NONE,
    error: {},
    totalItems: 0,
  },
  actions: {
    listCarrierReport({ commit }, { page, itemsPerPage, sort, filter }) {
      commit("SET_STATUS_LOADING");
      commit("UNSET_ITEMS");

      return api.reports
        .carrierReport(page, itemsPerPage, sort, filter)
        .then((response) => {
          commit("SET_ITEMS", response.data);
          commit("UNSET_STATUS");
        })
        .catch((error) => commit("SET_STATUS_ERROR", error));
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
  },
  mutations: {
    UNSET_CARRIER_REPORT_ITEMS(state) {
      state.carrierReportItems = [];
    },
    SET_STATUS_ERROR(state, error) {
      state.error = error;
      state.status = ReportStateStatus.ERROR;
    },
    SET_STATUS_LOADING(state) {
      state.error = {};
      state.status = ReportStateStatus.LOADING;
    },
    UNSET_STATUS(state) {
      state.error = {};
      state.status = ReportStateStatus.NONE;
    },
    SET_ITEMS(state, data) {
      state.carrierReportItems = data.results;
      state.totalItems = data.count;
    },
    UNSET_ITEMS(state) {
      state.carrierReportItems = [];
      state.totalItems = 0;
    },
  },
};

export default {
  reports,
  ReportStateStatus,
};
