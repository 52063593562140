import { api } from "@/services";

export const CurrencyStateStatus = {
  NONE: "",
  ERROR: "error",
};

export const currency = {
  namespaced: true,
  state: {
    items: [],
    status: CurrencyStateStatus.NONE,
    error: {},
  },
  actions: {
    list({ commit, state }) {
      //* Lazy caching since currencies, usually, don't suppose to change.
      if (!state.items.length) {
        commit("UNSET_ERROR");
        commit("UNSET_ITEMS");

        return api.currency
          .list()
          .then((currencies) => commit("SET_ITEMS", currencies.data))
          .catch((error) => commit("SET_ERROR", error));
      }

      return Promise.resolve();
    },
    unsetItems({ commit }) {
      commit("UNSET_ITEMS");
    },
  },
  mutations: {
    UNSET_ITEMS(state) {
      state.items = [];
    },
    UNSET_ERROR(state) {
      state.error = {};
      state.status = CurrencyStateStatus.NONE;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.status = CurrencyStateStatus.ERROR;
    },
    SET_ITEMS(state, items) {
      let transformedItems = [];
      for (let item in items) {
        transformedItems.push({ id: item, name: items[item] });
      }
      state.items = transformedItems;
    },
  },
};

export default {
  currency,
  CurrencyStateStatus,
};
